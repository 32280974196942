import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import HeaderCurrentPage from 'src/components/header-current-page/header-current-page'
import MembersReportArticle from 'src/components/members-report-article/members-report-article'
import CoverageArticle from 'src/components/coverage-article/coverage-article'
import Funnel from 'src/components/funnel/funnel'

import { useAuth } from 'src/hooks/AuthProvider'
import { getDurstion } from 'src/utils/prepareData'
import { IObj } from 'src/constants/types'
import { initState, content } from './data'
import { goBackInfo } from 'src/constants/links-data'
import { NOT_FOUND_URL } from 'src/constants/URLs'
import { ActiveBlock } from '../webinar/data'
import { API_URL } from 'src/constants'
import 'react-loading-skeleton/dist/skeleton.css'

const Article = (props: { materialType: 'article' | 'video' }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { materialType } = props
  const { userData } = useAuth()

  const [statData, setStatData] = useState({
    ...initState,
    isLoading: true
  })
  const [audience, setAudience] = useState([] as any[])
  const [activeBlock, setActiveBlock] = useState(ActiveBlock.coverage)

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(
          `${API_URL}/api/articlestat/getMainArticleInfo?id=${id}&materialType=${materialType}&sponsorId=${userData.personalId}&isAdmin=${Boolean(userData.isAdmin)}`
        )
        if (res.data?.isAccessError) {
          navigate(NOT_FOUND_URL)
          return
        }
        setStatData({ ...res.data, isLoading: false })
      } catch (err) {
        console.log(err)
      }
    }

    if (!materialType) return
    if (userData?.isHasAccess) getData()
  }, [userData])

  useEffect(() => {
    const getAudienceComposition = async () => {
      try {
        const res = await axios.get(`${API_URL}/api/articlestat/getArticleAudienceComposition?id=${id}`)

        setAudience(res?.data)
      } catch (err) {
        console.log(err)
      }
    }

    const getAverageWatchingTime = async (data: IObj<string | number | undefined>) => {
      try {
        const res = await axios.get(
          `${API_URL}/api/webinarstat/getAverageWatchingTime?materialType=${data.materialType}&id=${id}&isAdmin=${userData?.isAdmin}`
        )
        setStatData({
          ...statData,
          averagePercentage: getDurstion(res.data.averageTimeInMs),
          uniqueViews: res.data.uniqueWatchCount
        })
      } catch (err) {
        console.log(err)
      }
    }
    if (!statData.isLoading) {
      if (materialType === 'video') getAverageWatchingTime({ materialType: 'article', id })
      getAudienceComposition()
    }
  }, [statData.isLoading])

  const renderActiveBlock = (block: ActiveBlock) => {
    if (userData?.isHasAccess)
      switch (block) {
        case ActiveBlock.coverage:
          return <CoverageArticle statData={statData} materialType={materialType} />
        case ActiveBlock.report:
          return <MembersReportArticle materialType={materialType} audience={audience} id={Number(id)} />
        case ActiveBlock.funnel:
          return <Funnel id={Number(id)} materialType={materialType} startDate={statData.publicationDate} />
      }
  }

  return (
    <>
      <Helmet>
        <title>
          {content[materialType].helmetClickPage} {id}
        </title>
      </Helmet>
      <HeaderCurrentPage
        goBack={goBackInfo[materialType].link}
        txt={goBackInfo[materialType].txt}
        linkTitle={`https://medpoint.pro/article/${statData.linkTitle || id}`}
        title={statData.articleTitle}
        publicationDate={statData.publicationDate}
        audience={statData.specialities}
        changeBlock={setActiveBlock}
        activeBlock={activeBlock}
      ></HeaderCurrentPage>
      <WidthLimiter>{renderActiveBlock(activeBlock)}</WidthLimiter>
    </>
  )
}

export default Article
